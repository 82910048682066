.mainContainer {
    display: flex;
    justify-content: center;
}

.container {
    position: absolute;
    top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -1;
}

.subContainer {
    width: 83%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mainContent {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

@media (min-width:769px) {
    .mainContent {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 60px;
    }

}

.subContent {
    width: 100%;
}

@media screen and (min-width:769px) {
    .subContent {
        width: 80%;
    }

}

.subContent h5 {
    color: #0cae0e;
    font-size: var(--fs-700);
    font-family: "Product-Sans-Medium";
}

.subContent span {
    color: #063767;
}


.fullStack {
    color: #063767;
    font-family: "Product-Sans-Medium";
    font-size: var(--fs-100);
}

.paragraph {
    color: #111111;
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    /* border: 2px solid green; */
}

.subContent a {
    background-color: #0096D5;
    border: 0px;
    color: white;
    font-family: "Product-Sans-Medium";
    padding: 8px 15px;
    font-size: var(--fs-200);
    text-decoration: none;
}

.mainImgContainer img {
    width: 100%;
    object-fit: contain;
}

.brandContainer {
    /* margin-top: 20px; */
    margin-top: var(--mt-700);
}

.brandImgsContainer {
    display: flex;
    width: 100%;
    overflow: hidden;
    user-select: none;
}

.brandLogoCon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    white-space: nowrap;
    width: 100%;
    animation: ScrollX 15s linear infinite;

}

.brandLogoCon img {
    width: 15%;
    object-fit: contain;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 0px 0px;
}

@media (min-width:769px) {
    .brandLogoCon img {
        width: 10%;
    }
}

@keyframes ScrollX {

    from {
        left: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }

}

.line1 {
    background-color: black;
    height: var(--ht-10);
    width: 100%;
}


.helpingContainer {
    margin-top: var(--mt-700);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.helpingContainer h3 {
    color: #063767;
    /* margin-top: var(--mt-100); */
    font-size: var(--fs-600);
    padding-bottom: var(--mt-200);
    justify-content: center;
    text-align: center;
    font-family: "Product-Sans-Medium";

}

/* .helpingContainer p {
    text-align: center;
    font-size: var(--fs-200);
    font-weight: 200;
    font-family: "Product-Sans-Regular";

} */

.transportationContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 80%;
    margin-top: 40px;
    /* border: 2px solid red; */
}

.transportCard {
    display: flex;
    gap: 15px;
    flex-shrink: 0;
}

.transportCard img {
    width: 80px;
    object-fit: contain;
}


@media (min-width:769px) {
    .transportationContainer {
        flex-direction: row;
    }

    .transportCard {
        width: 50%;
    }

    .transportCard img {
        width: 120px;
        object-fit: contain;
    }

}





.transportDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.transportText {
    font-size: var(--fs-200);
    /* font-weight: 200; */
    font-family: "Product-Sans-Regular";
}

.transportDetails h5 {
    /* font-size: var(--fs-700); */
    font-size: var(--fs-900);
    /* color: #063767; */
    color: #111111;
    /* border: 2px solid blue; */
    font-family: "Product-Sans-Medium";
}

.urbanNeedsContainer {
    margin-top: var(--mt-700);
    background-color: #f8f9fb;
    padding: var(--mt-300) var(--mt-100) var(--mt-100) var(--mt-100);
    /* align-items: center;
    justify-content: center; */
    /* text-align: center; */
}

.urbanNeedsContainer h2 {
    color: #063767;
    font-size: var(--fs-600);
    font-family: "Product-Sans-Medium";
    padding-bottom: var(--mt-200);
    text-align: start;
    /* width: 80%; */
}

.urbanNeedslist {
    list-style-type: none;
    margin-top: var(--mt-200);
    /* margin-bottom: var(--mt-200); */
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
}

.urbanNeedsCard {
    display: flex;
    align-items: center;
    gap: var(--mt-200);
    margin-top: 4%;
    padding-bottom: 4%;
    /* width: 50%; */
}

.urbanNeedsCard img {
    width: 50px;
    object-fit: contain;
}

@media screen and (min-width:769px) {

    .urbanNeedslist {
        flex-direction: row;
        flex-wrap: wrap;
        /* border: 2px solid red; */
    }

    .urbanNeedsCard {
        width: 50%;
    }

    .urbanNeedsContainer h2 {
        width: 80%;
    }

}



.urbanNeedsCard h5 {
    padding-top: var(--pt-600);
    font-size: var(--fs-900);
    font-family: "Product-Sans-Medium";
    text-align: start;

}

.contactUsContainer {
    margin-top: 5%;
    text-align: start;
    /* border: 2px solid red; */

}

.contactUs {

    background-color: #0cae0e;
    border-radius: 2px;
    color: #fff;
    padding: var(--pt-600);
    padding-left: var(--pl-10);
    padding-right: var(--pl-10);
    font-size: var(--fs-900);
    font-family: "Product-Sans-Bold";
    text-decoration: none;
    /* margin-top: 50px; */
}

.contactUs:hover {
    color: white;
    text-decoration: none;
}

.ecoSystemContainer {
    display: flex;
    flex-direction: column;
    margin-top: var(--mt-700);
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
    /* height: 1000px; */
}

.ecoSystemContainer h2 {
    color: #063767;
    font-size: var(--fs-600);
    padding-bottom: var(--mt-200);
    text-align: center;
    font-family: "Product-Sans-Medium";
}

.ecoSystemContainer p {
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    text-align: center;
    width: 80%;
}

.ecosystemImgContainer {
    margin-top: var(--pt-500);
    background-image: url(../../../public/assests/images/Whistle-Electric/treesbg.png);
    /* background-image: url('../assests/images/Whistle-Electric/treesbg.png'); */
    background-size: cover;
    flex-direction: column;
    display: flex;
    /* width: 80%; */
    object-fit: contain;

    /* background-color: red;
    border: 2px solid blue; */
    /* border: 2px solid green !important; */
}

.ecosystemImg {
    width: 100%;
}

@media screen and (min-width:577px) {
    .ecosystemImgContainer {
        flex-direction: row;
    }

    .ecosystemImg {
        width: 50%;
    }

}



.ecosystemImg img {
    width: 100%;
    object-fit: contain;
}

.drivenContainer {
    margin-top: var(--mt-700);
    display: flex;
    flex-direction: column;

}

.drivenContent {
    background-color: #eff1f5;
    padding: var(--mt-300);

}

@media screen and (min-width:769px) {
    .drivenContainer {
        flex-direction: row;
    }

    .drivenContent {
        width: 50%;

    }

}




.drivenContent h2 {
    color: #063767;
    font-family: "Product-Sans-Medium";
    font-size: var(--fs-600);
    /* padding-top: var(--pt-500); */
    padding-bottom: var(--mt-200);
    line-height: var(--pt-900);
}

.drivenContent h5 {
    font-family: "Product-Sans-Medium";
    font-size: var(--fs-700);
    padding-top: var(--pt-300);
}

.drivenContentList {
    list-style-type: none;
    padding-left: 0px;
}

.drivenContentList h3 {
    font-size: var(--fs-900);
    font-family: "Product-Sans-Regular";
    padding: 10px;
}

.circle {
    font-size: var(--fs-400);
    color: #0cae0e;
    background: #cef6ce;
    padding: 1%;
    border-radius: 50%;
    margin-right: var(--mt-200);
}

.drivenImgContainer {
    background-color: #f8f9fb;
    display: flex;
    justify-content: center;
    /* padding-top: 5%;
    padding-bottom: 5%; */
}

.drivenImgContainer img {
    width: 80%;
    object-fit: contain;
}