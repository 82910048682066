.nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: white;
    width: 100%;
    padding: 15px 0px;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 83%;
}

.logo-container {
    width: 30%;
}

.logo {
    width: 45%;
    cursor: pointer;
}

@media (max-width: 992px) {
    .logo {
        min-width: 140px;

    }

}

.nav-items {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin: 0px;
}

@media (max-width: 992px) {
    .nav-item {
        display: none;
    }

    .nav-items {
        display: none;
    }
}

.nav-item:hover {
    color: #333333;
    text-decoration: none;
    align-self: center;
    height: 100%;
}

.nav-list-item:hover .dropdown {
    text-decoration: none;
    border-top: 3px solid #0096d5;
    color: #333333;
    padding: 15px 10px;
    border-radius: 4px;
    display: flex;
    position: absolute;
    flex-direction: column;
    /* background-color: #1b92c5;   */
    background-color: #f6f7fb;
    font-family: "Product-Sans-Regular";
}

.dropdown {
    display: none;
    text-decoration: none;
}

.dropdown a {
    color: #333333;
    font-family: "Product-Sans-Regular";
    text-decoration: none;
}

.mobile-drop-down {
    text-decoration: none;
    border-top: 3px solid #0096d5;
    color: #333333;
    padding: 15px 10px;
    border-radius: 4px;
    display: flex;
    /* position: absolute; */
    flex-direction: column;
    /* background-color: #1b92c5;   */
    background-color: #f6f7fb;
    font-family: "Product-Sans-Regular";
}

.nav-icon {
    background: #0096D5;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 5px;
}

@media (min-width: 992px) {
    .nav-icon {
        display: none;
    }

}

.nav-line {
    display: block;
    border-radius: 50px;
    width: 20px;
    height: 2px;
    margin: 5px;
    background-color: #fff;
    transition: width 0.8s ease-in-out;
}

.nav-line.open:nth-child(2) {
    width: 40%;
}

.mobilenavItems {
    list-style: none;
    margin: 0px;
    gap: 10px;
    font-family: "Poopins-Medium";
    position: absolute;
    right: 0px;
    height: 92vh;
    top: 9vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
    background-color: white;
    z-index: 9999;
    padding-left: 0px;

}

.mobilenavItems.open {
    transform: translateX(0%);
}

.mobilenavItems li a {
    text-decoration: none;
    color: #333333;
}

.mobile-nav-item {
    /* opacity: 0; */
    font-size: 12px;
    /* transition: opacity 0.4s ease-in-out; */
    display: flex;
    flex-direction: column;
    font-family: "Product-Sans-Regular";
    color: #333333;
    width: 80%;
    gap: 5px;
}

/* .mobile-nav-item.open {
    opacity: 1;
} */

.contact-us-btn {
    background-color: #0096D5;
    border: 0px;
    color: white;
    font-family: "Product-Sans-Medium";
    padding: 8px 15px;
    font-size: var(--fs-200);
}

.contact-us-btn a {
    color: white;
}

ul li a {
    color: #333333;
    font-family: "Product-Sans-Medium";
    text-decoration: none;
    font-size: var(--fs-200);
}

.mobile-nav-item h3 {
    color: #333333;
    font-family: "Product-Sans-Medium";
    text-decoration: none;
    font-size: var(--fs-200);
}