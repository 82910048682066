.mainContainer {
  display: flex;
  justify-content: center;
}

.container {
  position: absolute;
  top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.subContainer {
  width: 83%;
  display: flex;
  flex-direction: column;
}

.mainContent {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

}

@media (min-width:769px) {
  .mainContent {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
  }

}

.connected {
  color: #111111;
  font-size: 16px;
  font-family: "Product-Sans-Medium";
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.fullStack {
  color: #063767;
  font-family: "Product-Sans-Medium";
  font-size: var(--fs-100);
  /* border:2px solid red */
}

.paragraph {
  color: #111111;
  font-size: var(--fs-200);
  font-family: "Product-Sans-Regular";
}

.subContent a {
  background-color: #0096D5;
  border: 0px;
  color: white;
  font-family: "Product-Sans-Medium";
  padding: 8px 15px;
  font-size: var(--fs-200);
  text-decoration: none;
}

.videoContainer {
  width: 80%;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.brandContainer {
  margin-top: 20px;
}

.trustedpara {
  color: #111111;
  font-family: "Product-Sans-Medium";
  font-size: var(--fs-200);
  text-align: center;
}

.brandImgsContainer {
  display: flex;
  width: 100%;
  overflow: hidden;
  user-select: none;
}

.brandLogoCon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: ScrollX 15s linear infinite;

}

.brandLogoCon img {
  width: 15%;
  object-fit: contain;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 0px 0px;
}

@media (min-width:769px) {
  .brandLogoCon img {
    width: 10%;
  }
}

@keyframes ScrollX {

  from {
    left: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }

}

.line1 {
  background-color: black;
  height: var(--ht-10);
}

.MobilityContainer {
  margin-top: var(--mt-700);
  width: 100%;
}

.MobilityContainer h2 {
  font-size: var(--fs-800);
  color: #063767;
  font-family: "Product-Sans-Medium";
  width: 100%;
  text-align: center;
}

.transportationContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
}

.transportCard {
  display: flex;
  gap: 15px;
  flex-shrink: 0;
}

@media (min-width:992px) {
  .transportationContainer {
    flex-direction: row;
  }

  .transportCard {
    width: 50%;
  }
}



.transportCard img {
  width: 120px;
  object-fit: contain;
}

/* .transportDetails {
} */

.transportDetails h5 {
  font-size: var(--fs-700);
  color: #063767;
  font-family: "Product-Sans-Medium";
}

.transportDetails a {
  font-size: var(--fs-200);
  color: #0096d5;
  text-decoration: none;
  font-family: "Product-Sans-Regular";
  padding: 0px;
  margin: 0px;
}

.datadrivenContainer {
  width: 100%;
  margin-top: var(--mt-700);
}

.datadrivenContainer img {
  width: 100%;
}


@media (min-width:769px) {
  .datadrivenContainer {
    display: flex;
    flex-direction: row;
  }

  .datadrivenContent {
    order: 1;
    display: flex;
    flex-direction: column;
    align-self: center;
    padding-left: 20px;
    gap: 10px;
  }

  .datadrivenContainer img {
    width: 50%;
    order: 0;
  }

}

.datadrivenContainer h4 {
  color: #063767;
  font-family: "Product-Sans-Medium";
  font-size: var(--fs-600);
}

.whistleOslist {
  list-style-type: none;
  padding-left: 0px;
  font-family: "Product-Sans-Regular";
  font-size: var(--fs-200);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (min-width:769px) {
  .whistleOslist {
    gap: 20px;
  }
}

.tickIcon {
  font-size: var(--fs-400);
  color: #0096d5;
  background: #ccefff;
  padding: 1%;
  border-radius: 50%;
  margin-right: 6px;
}


.EtCard {
  background-color: #f8f9fb;
  padding: var(--pl-100);
}



.EtCard h6 {
  font-size: var(--fs-200);
  font-family: "Product-Sans-Regular";
}

.EtCard h2 {
  font-size: var(--fs-600);
  font-family: "Product-Sans-Medium";
  color: #063767;
  line-height: var(--lh-100);
}



.circleContainer {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 0px;
  margin-top: 10px;


}

.circle {
  font-size: var(--fs-400);
  color: #0096d5;
  background: #a7d5eb;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  border-radius: 50%;
}



.cardHeading {
  font-size: var(--fs-700);
  font-family: "Product-Sans-Medium";
}

.GoodsCard {
  background-color: #eff1f5;
  padding: var(--pl-100);
}

.GoodsCard h6 {
  font-size: var(--fs-200);
  font-family: "Product-Sans-Regular";
}

.GoodsCard h2 {
  font-size: var(--fs-600);
  font-family: "Product-Sans-Medium";
  color: #063767;
  line-height: var(--lh-100);
}

.clientContainer {
  margin-top: var(--mt-700);
}

.helpText {
  text-align: center;
  font-size: var(--fs-700);
  font-family: "Product-Sans-Medium";
}

.numbersContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

}

@media (min-width:769px) {
  .numbersContainer {
    justify-content: center;
    gap: 30px;
  }

  .clientContainer {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width:992px) {
  .numbersContainer {
    gap: 120px;
  }
}

.numbersCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.numbersCard h3 {
  font-size: var(--fs-100);
  font-family: "Product-Sans-Medium";
}

.numbersCard h6 {
  font-size: var(--fs-200);
  font-family: "Product-Sans-Regular";
}

.urbanMobilityContainer {
  margin-top: var(--mt-700);
}

.urbanMobilityContainer img {
  width: 100%;
  object-fit: contain;
}

.urbanMobilityCard {
  background-color: #EFF1F5;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width:769px) {
  .urbanMobilityContainer {
    display: flex;
  }

  .urbanMobilityContainer img {
    width: 50%;
  }

  .urbanMobilityContent {
    display: flex;
    flex-direction: column;
    padding-left: 20px;

  }
}



.urbanMobilityCard h6 {
  font-size: var(--fs-200);
  font-family: "Product-Sans-Regular";
}

.urbanMobilityCard h2 {
  color: #063767;
  font-size: var(--fs-600);
  font-family: "Product-Sans-Medium";
}

.urbanMobilityCard h3 {
  font-size: var(--fs-700);
  padding-top: var(--pt-300);
  font-family: "Product-Sans-Medium";
}

.urbanMobilityCard p {
  font-size: var(--fs-200);
  font-family: "Product-Sans-Regular";
}

.sustainableContainer {
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin-top: var(--mt-700);
}

.sustainableContainer img {
  width: 100%;
}

@media (min-width:769px) {
  .sustainableContainer {
    flex-direction: row;
  }

  .sustainableContainer img {
    width: 45%;
  }

  .sustainableContent {
    order: 1;
    align-self: center;
  }
}

/* .sustainableContent {
  padding: 20px;
} */

.sustainableContent h6 {
  font-size: var(--fs-200);
  font-family: "Product-Sans-Regular";
  padding-top: var(--m-70);
}

.sustainableContent h2 {
  font-size: var(--fs-600);
  font-family: "Product-Sans-Medium";
  color: #063767;
  padding-top: var(--pt-300);
}

.button {
  background-color: white;
  border: 2px solid green;
  color: green;
  padding: var(--pt-600);
  padding-left: var(--pl-200);
  padding-right: var(--pl-200);
  font-size: var(--fs-200);
  font-family: "Product-Sans-Bold";
  text-decoration: none;
}

.button:hover{
  background-color: green;
  color: white;
  text-decoration: none;
}

.helpusContainer {
  margin-top: var(--mt-700);
  margin-bottom: var(--mt-700);
  background: url('../../../public/assests/images/home.png');
  width: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

}

.helpusContent {
  margin-top: var(--im-300);
  background-color: #fff;
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--p-5);
}

.helpusContent h2 {
  padding-top: var(--im-800);
  font-size: var(--fs-10);
  font-family: "Product-Sans-Medium";
  color: #063767;
  text-align: center;
}

.footerContainer {
  margin-top: var(--mt-100);
  padding-top: var(--pt-700);
  background-color: #f8f9fa;
}
