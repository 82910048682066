.helpusContainer {
    margin-top: var(--mt-700);
    margin-bottom: var(--mt-700);
    /* background: url('../../../public/assests/images/home.png'); */
    width: 100%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

}

.helpusContent {
    margin-top: var(--im-300);
    background-color: #fff;
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: var(--p-5);
}

.helpusContent h2 {
    padding-top: var(--im-800);
    font-size: var(--fs-10);
    font-family: "Product-Sans-Medium";
    color: #063767;
    text-align: center;
}

.button {
    background-color: #0096d5;
    border-radius: 2px;
    color: #fff;
    padding: var(--pt-600);
    padding-left: var(--pl-200);
    padding-right: var(--pl-200);
    font-size: var(--fs-200);
    font-family: "Product-Sans-Bold";
    text-decoration: none;
}

.button:hover{
    text-decoration: none;
    color: white;
}