.mainContainer {
    display: flex;
    justify-content: center;
}

.container {
    position: absolute;
    top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -1;
}

.subContainer {
    width: 83%;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
}

.groupImg {
    width: 100%;
    object-fit: contain;
}

.urbanContent {
    margin-top: var(--mt-700);
}

.urbanContent h2 {
    font-size: var(--fs-100);
    font-family: "Product-Sans-Medium";
    color: #063767;
    /* font-size: var(--fs-100); */
    /* padding-top: var(--mt-300);
    padding-bottom: var(--mt-200); */
    justify-content: center;
    text-align: center;
}

.webelieveCotnainer {
    margin-top: var(--mt-700);
    background-color: #eff1f5;
    /* padding-left: var(--mt-300);
    padding-right: var(--mt-300); */
    padding-top: var(--pt-900);
    padding-bottom: var(--pt-900);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.webelieveCotnainer h2 {
    font-family: "Product-Sans-Medium";
    color: #063767;
    font-size: var(--fs-600);
    /* padding-top: var(--mt-200); */
    padding-bottom: var(--mt-200);
    justify-content: center;
    text-align: center;
}

.believeCardContent {
    display: flex;
    width: 80%;
    /* border: 2px solid red; */
    justify-content: space-between;
}

.believeCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100%;
}

.believeCard img {
    width: 40px;
    /* height: 40px; */
    object-fit: contain;
}

@media screen and (min-width:769px) {
    .believeCard img {
        width: 25%;
        /* border: 2px solid red; */
    }

}

.believeCard h5 {
    padding-top: var(--pl-10);
    font-family: "Product-Sans-Medium";
    font-size: var(--fs-900);
    text-align: center;
}

.teamHeading {
    margin-top: var(--mt-700);
}

.teamHeading h2 {
    color: #063767;
    font-size: var(--fs-100);
    font-family: "Product-Sans-Medium";
    /* padding-top: var(--mt-200);
    padding-bottom: var(--pl-100); */
    justify-content: center;
    text-align: center;
}

.teamMembersContainer {
    list-style-type: none;
    padding-left: 0px;
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--mt-700);
    width: 100%;
    /* justify-content: space-between; */
}

.explorerContainer {
    margin-top: var(--mt-700);
}

.explorerContainer h2 {
    font-family: "Product-Sans-Medium";
    color: #0e3e6c;
    font-size: var(--fs-100);
    /* padding-top: var(--mt-200);
    padding-bottom: var(--pl-100); */
    justify-content: center;
    text-align: center;
}

.formContainer {
    margin-top: var(--mt-700);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 2px solid green; */

}

.explorerFormContainer {
    width: 85%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* border: 2px solid red; */
}

.inputContainer {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}

@media screen and (min-width:769px) {
    .explorerFormContainer {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        /* border: 2px solid red; */
    }

    .inputContainer {
        width: 45%;
        /* border: 2px solid yellow; */
    }
}


.inputContainer label {
    font-size: var(--fs-200);
    font-family: "Product-Sans-Medium";
}

.input {
    background-color: #f5f5f5;
    border: none;
    padding: 10px;
    font-family: "Product-Sans-Regular";
    font-size: var(--fs-200);
    /* height: 100%; */
}

.fileinput {
    text-decoration: none;
    background-color: #cbcbcb;
    color: black;
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    padding-left: var(--mt-200);
    padding-right: var(--mt-200);
    padding-top: var(--pt-300);
}

.btnContainer {
    display: flex;
    justify-content: center;
    /* border: 2px solid red; */
    width: 100%;
    margin-bottom: var(--mt-700);
}

.btn {
    background-color: #0096d5;
    border: 0px;
    border-radius: 2px;
    color: #fff;
    padding: var(--pt-600);
    padding-left: var(--pl-200);
    padding-right: var(--pl-200);
    font-size: var(--fs-900);
    font-family: "Product-Sans-Bold";
    text-decoration: none;
}

.errorMsg {
    color: red;
    font-family: "Product-Sans-Regular";
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: var(--fs-200);
}