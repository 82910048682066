.mainContainer {
    display: flex;
    justify-content: center;
}

.container {
    position: absolute;
    top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -1;
    width: 100%;
    font-family: "Product-Sans-Regular";
    /* background-color: yellow; */
}

.container blockquote {
    border-left: 4px solid #ccc;
    /* padding-left: 10px; */
    background-color: red;
    color: #555;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 20px 0; */
}

.container blockquote blockquote{
    border-left: 4px solid #ccc;
    /* padding-left: 10px; */
    color: #555;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: green !important;
    /* margin: 20px 0; */
}


.subContainer {
    width: 83%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--pl-100);
    /* border: 2px solid green; */
}

.carouselContainer {
    width: 90%;
}

.carouselContainer h3 {
    color: #063767;
    font-family: "Product-Sans-Medium";
    font-size: var(--fs-600);
    margin-top: 20px;
    text-align: center;

}

.headings {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: var(--pl-100);
}

.headings h4 {
    color: #063767;
    font-family: "Product-Sans-Medium";
    font-size: var(--fs-900);
}

.headings h5 {
    color: #0096D5;
    font-family: "Product-Sans-Medium";
    font-size: var(--fs--800);
}

.blogsListContainer {
    list-style-type: none;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    /* margin-top: var(--pl-100); */
    justify-content: flex-start;
    width: 100%;
    /* border: 2px solid red; */
}

.blogCard {
    width: 30%;
    display: flex;
    flex-direction: column;
    /* height: 300px; */
    /* border: 1px solid blue; */

}

.blogCard img {
    width: 100%;
    object-fit: contain;
}

.blogCard i {
    align-self: flex-end;
}

.blogCard p {
    color: #8F8F8F;
    font-family: "Product-Sans-Medium";
    font-size: var(--fs--700);
}

.blogCard h4 {
    color: #063767;
    font-family: "Product-Sans-Medium";
    font-size: var(--fs--700);
}

.blogCard a {
    color: #0096D5;
    font-family: "Product-Sans-Medium";
    font-size: var(--fs--700);
}

.line1 {
    background-color: black;
    height: var(--ht-10);
    width: 100%;
}