.mainContainer {
    display: flex;
    justify-content: center;
}

.container {
    position: absolute;
    top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -1;
    width: 100%;
}

.subContainer {
    width: 83%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--pl-100);
    /* border: 2px solid green; */
}

.termsHeading {
    margin-top: var(--pl-100);
}

.termsHeading h2 {
    color: #063767;
    font-size: var(--fs-100);
    /* padding-top: var(--mt-200);
    padding-bottom: var(--mt-200); */
    /* justify-content: center; */
    font-family: "Product-Sans-Medium";
    text-align: center;
}

.termsContent {
    margin-top: var(--pl-100);
    width: 100%;
}

.termsContent h5 {
    font-size: var(--fs-800);
    font-family: "Product-Sans-Medium";
    padding-bottom: var(--mt-200);
    /* font-weight: 500; */
    color: #063767;
}

.termsContent p {
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    color: #666666;
    /* text-align: justify; */
}

.termsContent h4{
    font-size: var(--fs-900);
    font-family: "Product-Sans-Medium";
    color: #666666;
}

.termsContent ul {
    margin-left: var(--pl-5);
    color: #666666;
    list-style-position: outside;
}

.termsContent ul li {
    color: #666666;
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    padding-top: var(--pl-5);
    list-style-type: disc;
    /* text-align: justify; */
}

.termsContent ol {
    margin-left: var(--pl-5);
    color: #666666;
    list-style-position: outside;
}

.termsContent ol li {
    color: #666666;
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    padding-top: 5px;
    list-style-type: decimal;
    /* text-align: justify; */
  }
  