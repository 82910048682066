/* .techContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.techContent h3 {
    color: #063767;
    font-size: var(--fs-600);
    padding-top: var(--mt-200);
    padding-bottom: var(--mt-200);
    text-align: center;
    font-family: "Product-Sans-Medium";
}

.techContentCard {
    display: flex;
} */

.techCard {
    /* border: 2px solid red; */
    width: 40%;
}

@media screen and (min-width:769px) {
    .techCard {
        width: 30%;
    }

}

@media (max-width: 576px) {
    /* Styles for phones */
    .techCard{
        width: 100%;
    }
}

.techCard h5 {
    font-size: var(--fs-700);
    font-family: "Product-Sans-medium";
    text-align: start;
    padding-top: var(--mt-200);
    padding-bottom: var(--mt-200);
}

.techCard img {
    width: 30px;
    height: 30px;
    /* border: 2px solid red; */
}

.redefineContentlist {
    list-style-type: none;
    padding-left: 0px;
    font-family: "Product-Sans-Regular";
    font-size: var(--fs-200);
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

@media (min-width:769px) {
    .redefineContentlist {
        gap: 10px;
    }
}

.tickIcon {
    font-size: var(--fs-400);
    color: #0096d5;
    background: #ccefff;
    padding: 1%;
    border-radius: 50%;
    margin-right: 6px;
}