.mainContainer {
    display: flex;
    justify-content: center;
}

.container {
    position: absolute;
    top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -1;
    width: 100%;
}

.subContainer {
    width: 83%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--pl-100);
    /* border: 2px solid green; */
}

.qualityHeading {
    margin-top: var(--pl-100);
    
}

.qualityHeading h2 {
    color: #063767;
    font-size: var(--fs-100);
    /* padding-top: var(--mt-200); */
    font-family: "Product-Sans-Medium";
    /* justify-content: center; */
    /* text-align: center; */
}

.qualityContent {
    border: 2px solid #f5f5f5;
    padding: 10px;
    width: 45%;
    /* gap: ; */
    margin-top: var(--pl-100);
    margin-bottom: var(--pl-100);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.qualityContent h2 {
    /* color: #063767;
    font-size: var(--fs-100); */
    padding-top: var(--mt-200);
    font-family: "Product-Sans-Medium";
    /* justify-content: center;
    text-align: center; */
}

.qualityContent p {
    font-size: var(--fs-900);
    /* font-weight: 500; */
    font-family: "Product-Sans-Regular";
    text-align: center;
}

.qualityContent .para {
    font-size: var(--fs-900);
    font-family: "Product-Sans-Medium";
    /* font-weight: 600; */
    text-align: center;
}