.carouselContainer{

    width: 100%;
    margin-top: 20px;
    /* border: 2px solid red; */


}

.carouselContainer img{
    /* width: 100%; */
    object-fit: contain;
   
}
.img{
    /* width: 250px;
    height: 250px; */
    /* border: 2px solid red; */
    object-fit: contain;
    width: 100%;
}