@font-face {
  font-family: "Product-Sans-Regular";
  /* src: url(/assets/fonts/ProductSans-Regular.ttf); */
  src: url(../public/assests/fonts/ProductSans-Regular.ttf);
}

@font-face {
  font-family: "Product-Sans-Italic";
  src: url(../public/assests/fonts/ProductSans-Italic.ttf);
}

@font-face {
  font-family: "Product-Sans-Medium";
  src: url(../public/assests/fonts/ProductSans-Medium.ttf);
}

@font-face {
  font-family: "Product-Sans-Bold";
  src: url(../public/assests/fonts/ProductSans-Bold.ttf);
}

:root {
  --pl-5: 20px;
  --pl-10: 35px;
  --m-10: 10px;
  --m-20: 15px;
  --m-30: 20px;
  --m-40: 20px;
  --m-50: 70px;
  --m-60: 40px;
  --m-70: 35px;
  --pt-100: 10px;
  --pt-200: 8px;
  --pt-300: 4px;
  --pt-400: 35px;
  --pt-500: 30px;
  --pt-600: 15px;
  --pt-700: 60px;
  --pt-800: 50px;
  --pt-900: 40px;
  --ht-10: 1px;
  --ht-100: 40px;
  --ht-200: 42px;
  --ht-300: 40px;
  --ht-400: 35px;
  --ht-500: 60px;
  --wd-100: 100px;
  --fs-10: 40px;
  --fs-100: 46px;
  --fs-200: 16px;
  --fs-300: 14px;
  --fs-400: 12px;
  --fs-500: 10px;
  --fs-600: 32px;
  --fs-700: 20px;
  --fs-800: 26px;
  --fs-900: 18px;
  --br-100: 3px;
  --br-200: 30px;
  --mt-100: 120px;
  --mt-200: 20px;
  --mt-300: 80px;
  --mt-400: 180px;
  --mt-500: 20px;
  --mt-600: 20px;
  --mt-700:100px;
  --pb-100: 30px;
  --mr-100: 0px;
  --pl-100: 70px;
  --pl-200: 35px;
  --lh-100: 40px;
  --pd-100: 60px;
  --pd-200: 50px;
  --pd-300: 50px;
  --im-100: 120px;
  --im-200: 400px;
  --im-300: 200px;
  --im-400: 70px;
  --im-500: 750px;
  --im-600: 650px;
  --im-700: 600px;
  --im-800: 50px;
  --p-50: 24px;
  --p-100: 100%;
  --p-200: 100px;
  --p-300: 120px;
  --p-10: 0px;
  --p-5: 5%;
  --p-1: 30px;
  --p-2: 20px;
  --p-3: 50px;
  --p-4: 100px;
  --p-6: 100px;
  --p-7: 110px;
}

@media (max-width: 1100px) and (min-width: 601px) {
  :root {
    --pl-5: 15px;
    --pl-10: 22px;
    --m-10: 0px;
    --m-20: 5px;
    --m-30: 5px;
    --m-40: 0px;
    --m-50: 30px;
    --m-60: 5px;
    --m-70: 0px;
    --pt-100: 8px;
    --pt-200: 6px;
    --pt-300: 3px;
    --pt-400: 5px;
    --pt-500: 22px;
    --pt-600: 11px;
    --pt-700: 30px;
    --pt-800: 30px;
    --pt-900: 30px;
    --ht-10: 0.8px;
    --ht-100: 35px;
    --ht-200: 32px;
    --ht-300: 10px;
    --ht-400: 25px;
    --ht-500: 35px;
    --wd-100: 70px;
    --fs-10: 25px;
    --fs-100: 30px;
    --fs-200: 12px;
    --fs-300: 13px;
    --fs-400: 10px;
    --fs-500: 9px;
    --fs-600: 20px;
    --fs-700: 15px;
    --fs-800: 20px;
    --fs-900: 16px;
    --br-100: 2px;
    --br-200: 0px;
    --mt-100: 90px;
    --mt-200: 15px;
    --mt-300: 40px;
    --mt-400: 100px;
    --mt-500: 18px;
    --mt-600: 40px;
    --mt-700:85px;
    --pb-100: 10px;
    --mr-100: 0px;
    --pl-100: 40px;
    --pl-200: 25px;
    --lh-100: 25px;
    --pd-100: 45px;
    --pd-200: 35px;
    --pd-300: 38px;
    --im-100: 160px;
    --im-200: 300px;
    --im-300: 150px;
    --im-400: 55px;
    --im-500: 650px;
    --im-600: 450px;
    --im-700: 450px;
    --im-800: 40px;
    --p-50: 16px;
    --p-100: 80%;
    --p-300: 55px;
    --p-200: 50px;
    --p-10: 10px;
    --p-5: 3%;
    --p-1: 22px;
    --p-2: 15px;
    --p-3: 25px;
    --p-4: 70px;
    --p-6: 70px;
    --p-7: 90px;
  }
}

@media (max-width: 600px) {
  :root {
    --pl-5: 10px;
    --pl-10: 17px;
    --m-10: 0px;
    --m-20: 5px;
    --m-30: 10px;
    --m-40: 8px;
    --m-50: 25px;
    --m-60: 10px;
    --m-70: 15px;
    --pt-100: 5px;
    --pt-200: 4px;
    --pt-300: 2px;
    --pt-400: 5px;
    --pt-500: 14px;
    --pt-600: 8px;
    --pt-700: 20px;
    --pt-800: 0px;
    --pt-900: 20px;
    --ht-10: 0.5px;
    --ht-100: 30px;
    --ht-200: 22px;
    --ht-300: 5px;
    --ht-400: 10px;
    --ht-500: 15px;
    --wd-100: 50px;
    --fs-10: 20px;
    --fs-100: 25px;
    --fs-200: 10px;
    --fs-300: 11px;
    --fs-400: 9px;
    --fs-500: 8px;
    --fs-600: 16px;
    --fs-700: 12px;
    --fs-800: 15px;
    --fs-900: 13px;
    --br-100: 1px;
    --br-200: 0px;
    --mt-100: 60px;
    --mt-200: 10px;
    --mt-300: 30px;
    --mt-400: 60px;
    --mt-500: 16px;
    --mt-600: 80px;
    --mt-700:50px;
    --pb-100: 5px;
    --mr-100: 30px;
    --pl-100: 35px;
    --pl-200: 15px;
    --lh-100: 20px;
    --pd-100: 30px;
    --pd-200: 25px;
    --pd-300: 25px;
    --im-100: 0px;
    --im-200: 200px;
    --im-300: 80px;
    --im-400: 45px;
    --im-500: 450px;
    --im-600: 400px;
    --im-700: 300px;
    --im-800: 30px;
    --p-5: 2%;
    --p-50: 6px;
    --p-100: 50%;
    --p-200: 40px;
    --p-300: 45px;
    --p-10: 10px;
    --p-1: 0px;
    --p-2: 0px;
    --p-3: 0px;
    --p-4: 30px;
    --p-6: 0px;
    --p-7: 70px;
  }
}