.mainContainer {
    display: flex;
    justify-content: center;
}

.container {
    position: absolute;
    top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -1;
}

.subContainer {
    width: 83%;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
}

.mainContent {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

@media (min-width:769px) {
    .mainContent {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 60px;
    }

}

.subContent {
    width: 80%;
}

.subContent h5 {
    color: #111111;
    font-size: var(--fs-700);
    font-family: "Product-Sans-Medium";
}

.subContent span {
    color: #0096d5;
}


.fullStack {
    color: #063767;
    font-family: "Product-Sans-Medium";
    font-size: var(--fs-100);
}

.paragraph {
    color: #111111;
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
}

.subContent a {
    background-color: #0096D5;
    border: 0px;
    color: white;
    font-family: "Product-Sans-Medium";
    padding: 8px 15px;
    font-size: var(--fs-200);
    text-decoration: none;
}

.mainImgContainer img {
    width: 100%;
    object-fit: contain;
}

.brandContainer {
    /* margin-top: 20px; */
    margin-top: var(--mt-700);
}

.brandImgsContainer {
    display: flex;
    width: 100%;
    overflow: hidden;
    user-select: none;
}

.brandLogoCon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    white-space: nowrap;
    width: 100%;
    animation: ScrollX 15s linear infinite;

}

.brandLogoCon img {
    width: 15%;
    object-fit: contain;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 0px 0px;
}

@media (min-width:769px) {
    .brandLogoCon img {
        width: 10%;
    }
}

@keyframes ScrollX {

    from {
        left: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }

}

.line1 {
    background-color: black;
    height: var(--ht-10);
}

.whistleTechContainer {

    margin-top: var(--mt-700);
    background-color: #f8f9fb;
    padding: var(--pt-900);
    padding-left: var(--mt-300);

}

.techContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border:2px solid blue; */
}

.techContent h3 {
    color: #063767;
    font-size: var(--fs-600);
    padding-top: var(--mt-200);
    padding-bottom: var(--mt-200);
    text-align: center;
    font-family: "Product-Sans-Medium";
}

.techContentCard {
    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */
    gap: 40px;
    /* justify-content: space-between; */
    /* border: 2px solid blue; */
    /* justify-content: center; */
}

@media screen and (min-width:76px) {
    .techContentCard {
        gap: 20px
    }

}

/* .techCard{
    border: 2px solid red;
    width: 50%;
}

.techCard h5 {
    font-size: var(--fs-700);
    font-family: "Product-Sans-medium";
    text-align: start;
    padding-top: var(--mt-200);
}

.techCard img{
    width: 50px;
}

.redefineContentlist {
    list-style-type: none;
    padding-left: 0px;
    font-family: "Product-Sans-Regular";
    font-size: var(--fs-200);
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

@media (min-width:769px) {
    .redefineContentlist {
        gap: 20px;
    }
}

.tickIcon {
    font-size: var(--fs-400);
    color: #0096d5;
    background: #ccefff;
    padding: 1%;
    border-radius: 50%;
    margin-right: 6px;
} */

.ensuringSafetyContainer {
    display: flex;
    flex-direction: column;
    margin-top: var(--mt-700);
}

.ensuringSafetyContainer img {
    width: 100%;
    object-fit: contain;
}

.ensuringImgContainer {
    background-color: #f8f9fb;
    width: 100%;
    display: flex;
    height: var(--im-600);
    align-items: center;

}

.ensuringSafetyContent {
    background-color: #eff1f5;
    height: var(--im-600);
    padding: var(--pd-100) var(--pd-100) var(--pd-100) var(--pd-100);
}

@media screen and (min-width:769px) {
    .ensuringSafetyContainer {
        flex-direction: row;
    }

    .ensuringSafetyContent {
        padding: var(--pd-100) 0% var(--pd-100) var(--pd-100);
        width: 100%;
    }

}



.ensuringSafetyContent h2 {
    color: #063767;
    font-size: var(--fs-600);
    font-family: "Product-Sans-Medium";
    padding-right: var(--pd-100);
    padding-bottom: var(--mt-200);
}

.hover {
    padding: var(--mt-200);
    /* margin-left: var(--pd-200); */
    margin-right: 0%;
    background-color: #f3f3f3;
    /* border: 2px solid red; */
}

.hover h3 {
    position: relative;
    font-size: var(--fs-900);
    font-family: "Product-Sans-Medium";
    /* font-weight: 500; */
    cursor: pointer;
    /* outline: none; */
}

.hover:hover {
    background-color: #f8f9fb;
}

.circle1 {
    font-size: var(--fs-400);
    color: #ffffff;
    background: #d8d8d8;
    padding: 0.5%;
    border-radius: 50%;
    margin-right: var(--mt-200);
}

.activeCircle {
    font-size: var(--fs-400);
    color: #0096d5;
    background: #ccefff;
    padding: 0.5%;
    border-radius: 50%;
    margin-right: var(--mt-200);

}

.para {
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    padding-top: var(--mt-200);
    padding-left: var(--pt-400);
}

.yourEmployeeContainer {
    display: flex;
    flex-direction: column;
    margin-top: var(--mt-700);
    width: 100%;
}

.yourEmployeeContainer img {
    width: 100%;
    object-fit: contain;
}

@media screen and (min-width:769px) {
    .yourEmployeeContainer {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        /* gap: 20px; */
        /* border: 2px solid green; */
    }

    .yourEmployeeContent {
        order: 1;
        /* width: 80%; */
        /* border: 2px solid red; */
    }

    .imgContainer {
        width: 100%;
        margin-right: 5%;
    }

    .yourEmployeeContainer img {
        /* width: 80%; */
        object-fit: contain;

        /* border: 2px solid red; */
    }

}





.yourEmployeeContent h2 {
    padding-top: var(--pt-300);
    /* font-weight: 500; */
    color: #063767;
    font-size: var(--fs-600);
    font-family: "Product-Sans-Medium";
    padding-bottom: var(--mt-200);
    line-height: var(--lh-100);
}

.yourEmployeeContent h5 {
    font-size: var(--fs-900);
    font-family: "Product-Sans-Medium";
    color: #063767;
}

.fullControlContainer {
    display: flex;
    flex-direction: column;
    margin-top: var(--mt-700);
}

.fullControlContainer img {
    width: 100%;

}

.fullControlImgContainer {
    background-color: #f8f9fb;
    /* width: 100%; */
    display: flex;
    height: var(--im-600);
    align-items: center;
}

.fullControlContent {
    background-color: #eff1f5;
    height: var(--im-600);
    /* padding: var(--pd-100) var(--pd-100) var(--pd-100) var(--pd-100); */
    padding: var(--pd-200) var(--pd-200) var(--pd-200) var(--pd-200);
}

@media screen and (min-width:769px) {
    .fullControlContainer {
        flex-direction: row;
    }

    .fullControlContent {
        order: 1;
        width: 50%;
        flex-shrink: 0;
        padding: var(--pd-200) var(--pd-200) var(--pd-200) 0%;
        /* padding: var(--pd-100) 0% var(--pd-100) var(--pd-100); */
    }

    .fullControlContainer img {
        width: 90%;

    }

}



.fullControlContent h2 {

    color: #063767;
    font-size: var(--fs-600);
    font-family: "Product-Sans-Medium";
    padding-bottom: var(--mt-200);
    line-height: var(--lh-100);
    padding-left: var(--mt-200);
    margin-left: var(--mt-200);
    margin-top: var(--mt-200);
}



/* .fullControlContainer img{
    width:120px;
} */

.groundContainer {
    display: flex;
    flex-direction: column;
    margin-top: var(--mt-700);
}

.groundContainer img {
    width: 100%;
    height: 80%;
    object-fit: contain;

}

.groundImgContainer {
    background-color: #f8f9fb;
    width: 100%;
    display: flex;
    height: var(--im-600);
    align-items: center;
}

.groundContent {
    background-color: #eff1f5;
    height: var(--im-600);
    padding: var(--pd-100) 6% var(--pd-100) var(--pd-100);
    /* padding-right: 7%; */
}

@media screen and (min-width:769px) {
    .groundContainer {
        flex-direction: row;
    }

    .groundContent {
        /* order: 1; */
        width: 50%;
        flex-shrink: 0;
        /* padding-right: 0px; */
        padding: var(--pd-100) 0% var(--pd-100) var(--pd-100);
    }

}



.groundContent h2 {
    color: #063767;
    font-size: var(--fs-600);
    font-family: "Product-Sans-Medium";
    padding-right: var(--pd-100);
    padding-bottom: var(--mt-200);
}

.bgColor {
    background-color: #f8f9fb
}