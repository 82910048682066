.teamMemberCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 100%;
}

@media screen and (min-width:992px) {
    .teamMemberCard {
        width: 25%;
    }

}

.teamMemberCard img {
    /* width: 100%; */
    height: var(--im-300);
    width: var(--im-300);
    object-fit: contain;
    filter: saturate(1%);
    background-image: linear-gradient(to bottom, #E0E0E0, #7E7E7E);
}

.teamMemberCard h4 {
    margin-top: var(--mt-200);
    text-align: center;
    font-family: "Product-Sans-Medium";
    /* font-weight: 300; */
    font-size: var(--fs-900);
}

.teamMemberCard p {
    text-align: center;
    font-family: "Product-Sans-Regular";
    /* font-weight: 300; */
    font-size: var(--fs-300);
    margin-bottom: var(--pl-200);
}