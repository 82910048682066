.mainContainer {
    display: flex;
    justify-content: center;
}


.container {
    position: absolute;
    top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -1;
    /* background-color: red; */
}

.subContainer {
    width: 83%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mainContent {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

@media (min-width:769px) {
    .mainContent {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 60px;
    }

}

.subContent {
    width: 80%;
}

.subContent h5 {
    color: #111111;
    font-size: var(--fs-700);
    font-family: "Product-Sans-Medium";
}


.fullStack {
    color: #063767;
    font-family: "Product-Sans-Medium";
    font-size: var(--fs-100);
}

.paragraph {
    color: #111111;
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
}

.subContent a {
    background-color: #0096D5;
    border: 0px;
    color: white;
    font-family: "Product-Sans-Medium";
    padding: 8px 15px;
    font-size: var(--fs-200);
    text-decoration: none;
}

.mainImgContainer img {
    width: 100%;
    object-fit: contain;
}

.brandContainer {
    /* margin-top: 20px; */
    margin-top: var(--mt-700);
}

.brandImgsContainer {
    display: flex;
    width: 100%;
    overflow: hidden;
    user-select: none;
}

.brandLogoCon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    white-space: nowrap;
    width: 100%;
    animation: ScrollX 15s linear infinite;

}

.brandLogoCon img {
    width: 15%;
    object-fit: contain;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 0px 0px;
}

@media (min-width:769px) {
    .brandLogoCon img {
        width: 10%;
    }
}

@keyframes ScrollX {

    from {
        left: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }

}

.line1 {
    background-color: black;
    height: var(--ht-10);
    width: 100%;
    /* border: 2px solid red; */
}

.integratedContainer {
    margin-top: var(--mt-700);
    padding-top: var(--mt-700);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #EFF1F5;
    /* border: 2px solid red; */
}

.integratedContainer h3 {
    color: #063767;
    /* margin-top: var(--mt-100); */
    font-size: var(--fs-600);
    padding-bottom: var(--mt-200);
    justify-content: center;
    text-align: center;
    font-family: "Product-Sans-Medium";
}

/* .integratedContainer p {
    text-align: center;
    width: 100%;
    border: 2px solid red;
} */

.integrateSolution {
    display: flex;
    flex-direction: column;

    padding: 0px;
    gap: 10px;
    /* border: 2px solid blue; */
}

.solutionContainer {
    display: flex;
    /* width: 70%; */
    /* border: 2px solid red; */
    align-items: center !important;
    gap: 20px;
    /* gap: 40px; */
    /* justify-content: space-between; */
}

.solutionContainer p {
    padding: 0px;
    margin: 0px;
}

.usageContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: var(--mt-200);
    margin-bottom: var(--mt-700);
    gap: 40px;
    align-items: center;

}

@media screen and (min-width:769px) {
    .integrateSolution {
        /* flex-direction: row; */
        /* flex-wrap: wrap; */
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 20px;
        /* margin-top: 20px; */
        /* margin-top: var(--mt-700); */
    }

    .solutionContainer {
        width: 100%;
    }

    .usageContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: var(--mt-200);
        margin-bottom: var(--mt-700);
        gap: 40px;

    }

}

.usageContainer img {
    width: 50%;
    object-fit: contain !important;
}



.solutionContainer img {
    width: 40px;
    object-fit: contain;
    /* border: 2px solid green; */
}

.solutionContent {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start;
    border: 2px solid blue;
    padding: 0px;
    margin: 0px; */
}

.solutionContent h4 {
    font-size: var(--fs-700);
    font-family: "Product-Sans-Medium";
    color: #111111;
}

.whyUsContainer {
    margin-top: var(--mt-700);

    background-color: #eff1f5;
}

.whyUsContent {
    /* padding-top: var(--p-4);
    padding-left: var(--p-4);
    padding-right: var(--p-4); */
    padding: var(--p-4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.whyUsContent img {
    width: 65%;
    object-fit: contain;
    margin-bottom: var(--pt-500);
    /* border: 2px solid red; */
}

.whyUsContent h3 {
    font-size: var(--fs-600);
    font-family: "Product-Sans-Medium";
    color: #063767;
    padding-bottom: var(--pt-500);
}

.whyUsContent h4 {
    font-size: var(--fs-700);
    font-family: "Product-Sans-Medium";
}

.whyUsContent h6 {
    font-size: var(--fs-700);
    font-family: "Product-Sans-Medium";
    padding-bottom: var(--pt-500);
}

/* .whyusContentCard {}

.cardContent {} */

@media screen and (min-width:769px) {
    .whyusContentCard {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .cardContent {
        width: 50%;
    }
}

.cardContent h5 {
    font-size: var(--fs-900);
    font-family: "Product-Sans-Medium";
}

.cardContent p {
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    padding-right: var(--fs-100);
}

.technoImgContainer img {
    width: 100%;
    object-fit: contain;
}

.whyUsFooter {
    background-color: #f8f9fb;
    padding: var(--pd-300);
}

@media screen and (min-width:769px) {
    .whyUsFooter {
        display: flex;
        justify-content: space-between;
    }

}

.whyUsFooter h4 {
    font-size: var(--fs-700);
    font-family: "Product-Sans-Italic";
    color: #063767;
}

.whyUsFooter a {
    text-decoration: none;
    color: #0096d5;
    font-size: var(--fs-200);
    font-family: "Product-Sans-Medium";
}


.redefineContainer {
    width: 100%;
    margin-top: var(--mt-700);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* border: 2px solid green; */
    /* justify-content: center; */
}

.redefineContent {
    /* border: 2px solid red; */
    width: 80%;


}

.redefineContainer img {
    width: 80%;
}

.redefineContainer h4 {
    /* color: #063767;
    font-family: "Product-Sans-Medium";
    font-size: var(--fs-600); */
    color: #063767;
    /* padding-top: var(--p-6); */
    font-size: var(--fs-600);
    font-family: "Product-Sans-Medium";
    padding-bottom: var(--mt-200);
}

@media (min-width:769px) {
    .redefineContainer {
        display: flex;
        flex-direction: row;
        align-self: center;
        /* border: 2px solid #008000; */
        justify-content: center;
        /* justify-content: space-between; */
    }

    .redefineContent {
        /* order: 1; */
        display: flex;
        flex-direction: column;
        align-self: center;
        padding-right: 40px;
        gap: 10px;
        width: 40%;
        /* border: 2px solid blue; */
    }

    .redefineContainer img {
        width: 40%;
        /* order: 0; */
    }

    .redefineContainer h4 {
        padding-top: 0px;
    }



}



.redefineContentlist {
    list-style-type: none;
    padding-left: 0px;
    font-family: "Product-Sans-Regular";
    font-size: var(--fs-200);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.redefineContent {
    font-family: "Product-Sans-Regular";
    font-size: var(--fs-200);

}

@media (min-width:769px) {
    .redefineContentlist {
        gap: 20px;
    }
}

.tickIcon {
    font-size: var(--fs-400);
    color: #0096d5;
    background: #ccefff;
    padding: 1%;
    border-radius: 50%;
    margin-right: 6px;
}




.yourEmployeeContainer {
    display: flex;
    flex-direction: column;
    margin-top: var(--mt-700);
    width: 100%;
}

.yourEmployeeContainer img {
    width: 100%;
    object-fit: contain;
}


.imgContainer {
    width: 300px;
    margin-right: 5%;
}

@media screen and (min-width:769px) {
    .yourEmployeeContainer {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        /* gap: 20px; */
        /* border: 2px solid green; */
    }

    .yourEmployeeContent {
        order: 1;
        /* width: 80%; */
        /* border: 2px solid red; */
    }

    .imgContainer {
        width: 500px;
        margin-right: 5%;
    }

    .yourEmployeeContainer img {
        /* width: 80%; */
        object-fit: contain;

        /* border: 2px solid red; */
    }

}






.yourEmployeeContent h2 {
    padding-top: var(--pt-300);
    /* font-weight: 500; */
    color: #063767;
    font-size: var(--fs-600);
    font-family: "Product-Sans-Medium";
    padding-bottom: var(--mt-200);
    line-height: var(--lh-100);
}

.yourEmployeeContent h5 {
    font-size: var(--fs-900);
    font-family: "Product-Sans-Medium";
    color: #063767;
}

/* .img {
    width: 10%;
} */

.carouselContainer {
    width: 300px;
    margin-top: var(--mt-700);
}

@media screen and (min-width:769px) {
    .carouselContainer {
        width: 800px;
    }

}





.carouselContainer h3 {
    font-size: var(--fs-600);
    font-family: "Product-Sans-Medium";
    color: #063767;
    padding-bottom: var(--pt-500);
    text-align: center;
}

.carouselsImgContainer {
    display: flex;
    flex-direction: row;
}

.carouselsImgContainer img {
    width: 80px;
    object-fit: contain;
}

.carouselsImgContainer h4 {
    font-size: 12px;
    font-family: "Product-Sans-Medium";
    color: #063767;
    /* padding-bottom: var(--pt-500); */
    /* text-align: center; */

}

.carouselsImgContainer p {
    color: #4D4D4D;
    font-family: "Product-Sans-Regular";
    font-size: 10px;
}

@media screen and (min-width:769px) {
    .carouselsImgContainer img {
        width: 200px;
        object-fit: contain;
    }

    .carouselsImgContainer h4 {
        font-size: 16px;
        font-family: "Product-Sans-Medium";
        color: #063767;
        /* padding-bottom: var(--pt-500); */
        /* text-align: center; */

    }

    .carouselsImgContainer p {
        color: #4D4D4D;
        font-family: "Product-Sans-Regular";
        font-size: 12px;
    }

}