.mainContainer {
    display: flex;
    justify-content: center;
}

.container {
    position: absolute;
    top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -1;
}

.subContainer {
    width: 83%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mainContent {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

@media (min-width:769px) {
    .mainContent {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 60px;
    }

}

.subContent {
    width: 80%;
}

.subContent h5 {
    color: #111111;
    font-size: var(--fs-700);
    font-family: "Product-Sans-Medium";
}

.subContent span {
    color: #0096d5;
}


.fullStack {
    color: #063767;
    font-family: "Product-Sans-Medium";
    font-size: var(--fs-100);
}

.paragraph {
    color: #111111;
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
}

.subContent a {
    background-color: #0096D5;
    border: 0px;
    color: white;
    font-family: "Product-Sans-Medium";
    padding: 8px 15px;
    font-size: var(--fs-200);
    text-decoration: none;
}

.mainImgContainer img {
    width: 100%;
    object-fit: contain;
}

.brandContainer {
    /* margin-top: 20px; */
    margin-top: var(--mt-700);
}

.brandImgsContainer {
    display: flex;
    width: 100%;
    overflow: hidden;
    user-select: none;
}

.brandLogoCon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    white-space: nowrap;
    width: 100%;
    animation: ScrollX 15s linear infinite;

}

.brandLogoCon img {
    width: 15%;
    object-fit: contain;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 0px 0px;
}

@media (min-width:769px) {
    .brandLogoCon img {
        width: 10%;
    }
}

@keyframes ScrollX {

    from {
        left: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }

}

.line1 {
    background-color: black;
    height: var(--ht-10);
    width: 100%;
}

.oneOsContainer {
    display: flex;
    margin-top: var(--mt-700);
    flex-direction: column;
    width: 80%;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
}

/* .onOsContent{
    width: 80%;
} */

@media screen and (min-width:769px) {
    .oneOsContainer {
        flex-direction: row;
    }

    .onOsContent {
        order: 1;
        width: 50%;
        padding-left: var(--p-3);
    }

    .imgContainer {
        width: 60%;
    }

    /* .oneOsContainer img{
        width: 20%;
        object-fit: contain;
    } */

}

.oneOsContainer img {
    width: 100%;
    object-fit: contain;
}



.onOsContent h4 {
    color: #063767;
    padding-top: var(--pt-100);
    font-size: var(--fs-600);
    font-family: "Product-Sans-Medium";
    padding-bottom: var(--pt-100);
}

.Contentlist {
    list-style-type: none;
    padding-left: 0px;
    font-family: "Product-Sans-Regular";
    font-size: var(--fs-200);
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
}

@media (min-width:769px) {
    .Contentlist {
        gap: 20px;
    }
}

.tickIcon {
    font-size: var(--fs-400);
    color: #0096d5;
    background: #ccefff;
    padding: 1%;
    border-radius: 50%;
    margin-right: 6px;
}

.technologyContainer {
    display: flex;
    flex-direction: column;
}

.fullControlContainer {
    display: flex;
    flex-direction: column;
    margin-top: var(--mt-700);
}

.fullControlContainer img {
    width: 80%;

}

.fullControlImgContainer {
    background-color: #f8f9fb;
    /* width: 100%; */
    display: flex;
    height: var(--im-500);
    /* height: var(--im-600); */
    align-items: center;
    justify-content: center;
}

.fullControlContent {
    background-color: #eff1f5;
    /* height: var(--im-600); */
    height: var(--im-500);
    /* padding: var(--pd-100) var(--pd-100) var(--pd-100) var(--pd-100); */
    padding: var(--pd-200) var(--pd-200) var(--pd-200) var(--pd-200);
}

@media screen and (min-width:769px) {
    .fullControlContainer {
        flex-direction: row;
    }

    .fullControlContent {
        /* order: 1; */
        width: 50%;
        flex-shrink: 0;
        /* padding: var(--pd-200) var(--pd-200) var(--pd-200) 0%; */
        padding: var(--pd-100) 0% var(--pd-100) var(--pd-100);
    }

    .fullControlContainer img {
        width: 90%;
        /* border: 2px solid red; */

    }

}



.fullControlContent h2 {

    color: #063767;
    font-size: var(--fs-600);
    font-family: "Product-Sans-Medium";
    padding-bottom: var(--mt-200);
    line-height: var(--lh-100);
    /* padding-left: var(--mt-200); */
    /* margin-left: var(--mt-200); */
    margin-top: var(--mt-200);
}



/* .fullControlContainer img{
    width:120px;
} */


.hover {
    padding: var(--mt-200);
    /* margin-left: var(--pd-200); */
    margin-right: 0%;
    background-color: #f3f3f3;
    /* border: 2px solid red; */
}

.hover h3 {
    position: relative;
    font-size: var(--fs-900);
    font-family: "Product-Sans-Medium";
    /* font-weight: 500; */
    cursor: pointer;
    /* outline: none; */
}

.hover:hover {
    background-color: #f8f9fb;
}

.circle1 {
    font-size: var(--fs-400);
    color: #ffffff;
    background: #d8d8d8;
    padding: 0.5%;
    border-radius: 50%;
    margin-right: var(--mt-200);
}

.activeCircle {
    font-size: var(--fs-400);
    color: #0096d5;
    background: #ccefff;
    padding: 0.5%;
    border-radius: 50%;
    margin-right: var(--mt-200);

}

.para {
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    padding-top: var(--mt-200);
    padding-left: var(--pt-400);
}

.operatingSystemContainer {
    width: 80%;
    margin-top: var(--mt-700);
    display: flex;
    flex-direction: column;
}

.operatingSystemContainer img{
    width: 100%;
    object-fit: contain;
}

.operatingSystemContent {
    background-color: #eff1f5;
    padding: var(--pd-200);
}

@media screen and (min-width:769px){

    .operatingSystemContainer{
        flex-direction: row;
    }

    .osImgContainer{
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: #f8f9fb;
    }
    .operatingSystemContent{
        order: 1;
        /* width: 50%; */
    }
    
}



.operatingSystemContent h4 {
    color: #063767;
    padding-top: var(--mt-200);
    font-size: var(--fs-600);
    padding-bottom: var(--pt-100);
    /* padding-left: var(--mt-200); */
    font-family: "Product-Sans-Medium";
}


.operatingSystemContent p {
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    padding-top: var(--pt-100);
    /* padding-left: var(--mt-200); */
    padding-bottom: var(--mt-200);
}