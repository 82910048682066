.mainContainer {
    display: flex;
    justify-content: center;
}

.container {
    position: absolute;
    top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -1;
    width: 100%;
}

.subContainer {
    width: 83%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--pl-100);
    /* border: 2px solid green; */
}

.headings {
    margin-top: var(--pl-100);
}

.headings h5 {
    font-size: var(--fs-700);
    font-family: "Product-Sans-Medium";
    /* font-weight: 400; */
    justify-content: center;
    text-align: center;
}

.headings h2 {
    color: #063767;
    font-size: var(--fs-100);
    padding-top: var(--mt-200);
    padding-bottom: var(--mt-200);
    justify-content: center;
    font-family: "Product-Sans-Medium";
    text-align: center;
}

.formContainer {
    background-color: #eff1f5;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: var(--pl-100);
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;

}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

@media screen and (min-width:769px) {
    .form {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

}

.inputContainer {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}

@media screen and (min-width:769px) {


    .inputContainer {
        width: 31%;
        /* border: 2px solid yellow; */
    }
}


.inputContainer label {
    font-size: var(--fs-200);
    font-family: "Product-Sans-Medium";
}

.input {
    background-color: white;
    border: none;
    padding: 10px;
    width: 100%;
    font-family: "Product-Sans-Regular";
    font-size: var(--fs-200);
    /* height: 100%; */
}


.btnContainer {
    display: flex;
    justify-content: center;
    /* border: 2px solid red; */
    width: 100%;
    margin-top: var(--pl-100);
    /* margin-bottom: var(--mt-700); */
}

.btn {
    background-color: #0096d5;
    border-radius: 2px;
    color: #fff;
    border: 0px;
    padding: var(--pt-600);
    padding-left: var(--pl-10);
    padding-right: var(--pl-10);
    font-size: var(--fs-300);
    font-family: "Product-Sans-Medium";
    /* font-weight: 200; */
    text-decoration: none;
}


.errorMsg {
    color: red;
    font-family: "Product-Sans-Regular";
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: var(--fs-200);
}

.enquiryContainer {
    margin-top: var(--pl-100);
}

.enquiryContainer h5 {
    font-size: var(--fs-700);
    font-family: "Product-Sans-Medium";
    /* font-weight: 500; */
    /* justify-content: center; */
    text-align: center;
    /* padding-bottom: var(--pl-100); */
}

.addressMainContainer {
    margin-top: var(--pl-100);
    width: 90%;

}
.addressMainContainer img {
    width: 100%;
    object-fit: contain;
}
@media screen and (min-width:769px) {
    .addressMainContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
    .addressMainContainer img {
        width: 45%;
        object-fit: contain;
    }
    .addressContainer {
        padding-right: 3%;
    }

    
}




.addressContainer h4 {
    font-family: "Product-Sans-Medium";
    color: #063767;
    /* padding-top: var(--pl-100); */
    font-size: var(--fs-600);
    padding-bottom: var(--pt-100);

}

.addressContainer p {
    font-size: var(--fs-300);
    /* font-weight: 400; */
    font-family: "Product-Sans-Medium";
    padding-top: var(--pt-100);
}

.addressContainer p span {
    font-size: var(--fs-300);
    font-family: "Product-Sans-Bold";
    /* border: 1px solid red; */
    padding-top: var(--pt-100);
}

.locatedContainer {
    margin-top: var(--pl-100);
    display: flex;
    flex-direction: column;
    width: 100% !important;
    /* border:2px solid red; */

}
.locatedContainer img{
    width: 80%;
    object-fit: contain;
}
.imgContainer{
    display: flex;
    justify-content: center;
    padding-top: 3%;
}
@media screen and (min-width:769px) {
    .locatedContainer{
        flex-direction: row;
        /* justify-content: center; */
        /* align-items: center; */
    }
    .imgContainer{
        /* display: flex;
        justify-content: center; */
        width: 50%;
        padding-top: 0px;
    }
    .locatedContainer img{
        width: 80%;
        /* border: 2px solid red; */
    }
    
}



.locationsContainer {
    background-color: #eff1f5;
    padding: 5%;
    /* padding: var(--mt-200); */
}

@media screen and (min-width:769px) {
    .locatedContainer{
        width: 50%;
    }
    .locationsContainer{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
}

.locationsContainer h4 {
    color: #063767;
    /* padding-top: var(--mt-300); */
    font-size: var(--fs-600);
    font-family: "Product-Sans-Medium";
    /* padding-left: var(--pt-400);
    padding-bottom: var(--pt-100); */
}

.locationsContent {
    list-style-type: none;
    padding-left: 0px;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* border: 2px solid red; */
}

.locationsContent li{
    width: 30%;
    /* border: 2px solid blue; */

}

.locationsContent h5 {
    font-family: "Product-Sans-Medium";
    font-size: var(--fs-300);
    padding-top: var(--mt-200);
}