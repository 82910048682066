.mainContainer {
    display: flex;
    justify-content: center;
}

.container {
    position: absolute;
    top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -1;
    width: 100%;
}

.subContainer {
    width: 83%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--pl-100);
    /* border: 2px solid green; */
}

.privacyHeading {
    margin-top: var(--pl-100);
}

.privacyHeading h2 {
    color: #063767;
    font-size: var(--fs-100);
    /* padding-top: var(--mt-200);
    padding-bottom: var(--mt-200); */
    font-family: "Product-Sans-Medium";
    /* justify-content: center; */
    text-align: center;
}

.privacyContent {
    margin-top: var(--pl-100);
    /* border: 2px solid red; */
    width: 100%;
}

.privacyContent h5 {
    font-size: var(--fs-800);
    /* font-weight: 500; */
    padding-bottom: var(--mt-200);
    font-family: "Product-Sans-Medium";
    color: #063767;
}

.privacyContent p {
    font-size: var(--fs-200);

    font-family: "Product-Sans-Regular";
    /* font-weight: 500; */
    color: #666666;
    /* text-align: justify; */
}

.privacyContent ul {
    margin-left: var(--pl-5);
    color: #666666;
    list-style-position: outside;
}

.privacyContent ul li {
    color: #666666;
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    padding-top: var(--pl-5);
    list-style-type: disc;
}

.privacyContent  p a {
    text-decoration: none;
    color: #0096d5;
  }