.mainContainer {
    display: flex;
    justify-content: center;
}

.container {
    position: absolute;
    top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -1;
}

.subContainer {
    width: 83%;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
}

.mainContent {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

@media (min-width:769px) {
    .mainContent {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 60px;
    }

}

.subContent {
    width: 80%;
}

.subContent h5 {
    color: #111111;
    font-size: var(--fs-700);
    font-family: "Product-Sans-Medium";
}


.fullStack {
    color: #063767;
    font-family: "Product-Sans-Medium";
    font-size: var(--fs-100);
}

.paragraph {
    color: #111111;
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
}

.subContent a {
    background-color: #0096D5;
    border: 0px;
    color: white;
    font-family: "Product-Sans-Medium";
    padding: 8px 15px;
    font-size: var(--fs-200);
    text-decoration: none;
}

.mainImgContainer img {
    width: 100%;
    object-fit: contain;
}

.brandContainer {
    /* margin-top: 20px; */
    margin-top: var(--mt-700);
}

.brandImgsContainer {
    display: flex;
    width: 100%;
    overflow: hidden;
    user-select: none;
}

.brandLogoCon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    white-space: nowrap;
    width: 100%;
    animation: ScrollX 15s linear infinite;

}

.brandLogoCon img {
    width: 15%;
    object-fit: contain;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 0px 0px;
}

@media (min-width:769px) {
    .brandLogoCon img {
        width: 10%;
    }
}

@keyframes ScrollX {

    from {
        left: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }

}

.line1 {
    background-color: black;
    height: var(--ht-10);
    width: 100%;
}

.powerBusinessContainer {
    background-color: #eff1f5;
    padding: var(--mt-300);
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: var(--mt-700);
}

.powerBusinessContainer h2 {
    color: #063767;
    font-size: var(--fs-600);
    /* padding-top: var(--mt-200); */
    padding-bottom: var(--pt-900);
    justify-content: center;
    text-align: center;
    font-family: "Product-Sans-Medium";
}

.powerBusinessCardContainer {
    display: flex;
    list-style-type: none;
    padding-left: 0px;
    flex-direction: column;
    gap: var(--mt-200);
}

.powerBusinessCard {
    display: flex;
    gap: 15px;
}

@media screen and (min-width:769px) {
    .powerBusinessCardContainer {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 40px;
    }

    .powerBusinessCard {
        width: 45%;
    }

}



.powerBusinessCard img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    /* border: 2px solid red; */
}

.powerBusinessCardContent {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start;
    align-items: flex-start; */
    /* border: 2px solid red; */
}

.powerBusinessCardContent h5 {
    font-size: var(--fs-900);
    font-family: "Product-Sans-Medium";
    text-align: start;
}

.powerBusinessCardContainer p {
    /* border: 2px solid blue; */
    text-align: start
}

.transformingContainer {

    margin-top: var(--mt-700);
    padding: var(--lh-100);
    display: flex;
    flex-direction: column;
    /* border: 2px solid red; */
    justify-content: center;

}

.transformingContainer h3 {
    color: #063767;
    font-size: var(--fs-600);
    padding-top: var(--mt-200);
    padding-bottom: var(--mt-200);
    text-align: center;
    font-family: "Product-Sans-Medium";
}

.transformingContainer h4 {
    font-size: var(--fs-900);
    /* padding-top: var(--mt-200); */
    padding-bottom: var(--mt-200);
    text-align: center;
    font-family: "Product-Sans-Medium";
}

.transportingMilesContainer {
    list-style-type: none;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    /* border: 2px solid blue; */

}

.transportingCard {
    /* border: 2px solid red; */
    padding-top: var(--pt-100);
    margin-top: var(--pt-200);
}

.transportingCard img {
    width: 100px;
    object-fit: contain;
    /* border: 2px solid blue; */
}

@media screen and (min-width:577px) {
    .transportingMilesContainer {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .transportingCard {
        width: 40%;
    }

    .transportingCard img {
        width: 100px;
    }

}

@media screen and (min-width:769px) {
    .transformingContainer {
        /* gap: 20px; */
    }

    .transportingCard {
        width: 30%;
    }

    .transportingCard img {
        width: 40%;
        /* border: 2px solid blue; */
    }

}



.transportingCard h5 {
    font-size: var(--fs-700);
    font-family: "Product-Sans-Medium";
    text-align: start;
    padding-top: var(--mt-200);
    /* margin-left: var(--pt-500); */

}

.transportingCard p {
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    text-align: start;
    /* padding-top: var(--mt-200); */
    /* margin-left: var(--pt-500); */
}

.drivingEfficiencyContainer {
    display: flex;
    flex-direction: column;
    margin-top: var(--mt-700);
    /* border: 2px solid green; */
}

@media screen and (min-width:769px) {
    .drivingEfficiencyContainer {
        flex-direction: row;
    }

}

.drivingEfficiencyContent {
    /* border: 2px solid blue; */
    padding-top: 2%;
    /* padding-left: var(--p-3); */
    padding-bottom: 2%;
    /* border: 2px solid blue; */
}

@media screen and (min-width:769px) {
    .drivingEfficiencyContent {
        padding-left: var(--p-3);
        order: 1;
        /* border: 2px solid red; */

    }

}

.drivingEfficiencyContent h4 {
    color: #063767;
    font-size: var(--fs-600);
    font-family: "Product-Sans-Medium";
    padding-bottom: var(--pt-100);
}

.drivingEfficiencyContent p {
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    padding-top: var(--pt-100);

}

.Contentlist {
    list-style-type: none;
    padding-left: 0px;
    font-family: "Product-Sans-Regular";
    font-size: var(--fs-200);
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
}

@media (min-width:769px) {
    .Contentlist {
        gap: 20px;
    }
}

.tickIcon {
    font-size: var(--fs-400);
    color: #0096d5;
    background: #ccefff;
    padding: 1%;
    border-radius: 50%;
    margin-right: 6px;
}

.linkContact {
    background-color: #0096d5;
    border-radius: 2px;
    color: #fff;
    padding: var(--pt-600);
    padding-left: var(--pl-10);
    padding-right: var(--pl-10);
    font-size: var(--fs-900);
    font-family: "Product-Sans-Bold";
    text-decoration: none;
    /* margin-top: 10px; */
}

.linkContact:hover{
    text-decoration: none;
    color: white;
}

.imgContainer {
    background-color: #f8f9fb;
    padding-left: 0%;
    width: 100%;
    display: flex;
    align-items: center;
    /* border: 2px solid red; */
    padding-top: var(--pt-500);
    padding-bottom: var(--pt-500);
}

.imgContainer img {
    width: 80%;
    object-fit: contain;
}

@media screen and (min-width:769px) {
    .imgContainer {
        order: 0;
    }

}

.d2cLogisticsContainer {

    margin-top: var(--mt-700);
    background-color: #eff1f5;
    padding: var(--pt-900);
    /* height: auto; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.stepsCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.line2 {
    background-color: #000000;
    height: 80px;
    margin-top: var(--pd-100);
    width: 2px;
    text-align: center;

}

@media screen and (min-width:769px) {
    .line2 {
        height: 2px;
        width: 10%;
    }

}

/* .d2cLogisticsContainer hr {} */

.d2cLogisticsContainer p {
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    text-align: center;
}

@media screen and (min-width:769px) {

    /* .d2cLogisticsContainer hr {} */

    .stepsCardContainer {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        /* align-items: center; */
    }

}

.d2cLogisticsContainer h2 {
    /* color: #063767;
    font-size: var(--fs-200);
    font-family: "Product-Sans-Medium";
    padding-top: var(--pt-500);
    text-align: center;
    line-height: 1.5; */

    color: #063767;
    font-size: var(--fs-600);
    font-family: "Product-Sans-Medium";
    padding-top: var(--pt-500);
    text-align: center;
    line-height: 1.5;
    width: 80%;

}

.stepsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
    /* border: 2px solid red; */
    /* width: 20%; */
}

.stepsContainer img {
    width: 120px;
    object-fit: contain;
}

@media screen and (min-width:769px) {
    .stepsContainer {
        width: 25%;
        /* flex-direction: row;
        justify-content: flex-start; */
    }

    .stepsContainer img {
        width: 120px;
        height: 120px;
        /* border: 2px solid red; */
    }

}



.stepsContainer h2 {
    color: #8f8f8f;
    font-size: var(--fs-100);
    font-family: "Product-Sans-Medium";
    /* padding-top: var(--pt-900); */
    /* margin-left: var(--pt-100); */
    text-align: center;
}

.stepsContainer h4 {
    /* padding-top: 40px; */
    color: #063767;
    font-size: var(--fs-700);
    font-family: "Product-Sans-Medium";
    text-align: center;

}

.stepsContainer p {
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    text-align: center;

    /* padding-bottom: var(--pt-900); */
}

.percentageContainer {
    margin-top: var(--mt-700);
    width: 100%;
}


@media screen and (min-width:769px) {
    .percentageContainer {
        width: 80%;
        /* border: 2px solid red; */
    }

}

.numbersContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

}

@media (min-width:769px) {
    .numbersContainer {
        justify-content: space-around;
        /* gap: 30px; */
        /* width: 100%; */
    }

    .percentageContainer {
        display: flex;
        flex-direction: column;
    }
}

@media (min-width:992px) {
    .numbersContainer {
        gap: 120px;
    }
}

.numbersCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border:  2px solid red; */
    height: 100%;
    /* width: 30%; */

}

.numbersCard h3 {
    font-size: var(--fs-100);
    font-family: "Product-Sans-Medium";
}

.numbersCard h6 {
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    text-align: center;
}

.fulfillmentContainer {
    margin-top: var(--mt-700);
    width: 80%;
    display: flex;
    flex-direction: column;

}

.fulfillmentContainer img {
    width: 100%;
    object-fit: contain;
}

@media screen and (min-width:769px) {

    .fulfillmentContainer {
        flex-direction: row;
        /* width: 100% !important; */
        /* border: 2px solid blue; */
    }

    .fulfillmentContent {
        order: 1;
        /* width: 100%; */

        /* border: 2px solid red; */
        padding-left: var(--p-3);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .fulfillmentContainer {
        /* width: 50%; */
    }

    .fulfillmentContainer img {
        width: 50%;
        /* width: 50% !important;
        height: 50% !important;
        object-fit: contain;
        border: 2px solid red; */
    }

}

.fulfillmentContainer h4 {
    color: #063767;
    padding-top: var(--pt-100);
    font-size: var(--fs-600);
    font-family: "Product-Sans-Medium";
    padding-bottom: var(--pt-100);
}

.fulfillmentContainer p {
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    padding-top: var(--pt-100);
    /* padding-left: var(--pt-500); */

    padding-bottom: var(--pt-100);
}

.electricFleet {
    margin-top: var(--mt-700);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.electricFleet h3 {
    color: #063767;
    font-size: var(--fs-600);
    font-family: "Product-Sans-Medium";
    padding-top: var(--mt-200);
    padding-bottom: var(--mt-200);
    /* justify-content: center; */
    text-align: center;
    /* padding-left: 10%;
    padding-right: 10%; */

}

.electricFleet h4 {
    font-size: var(--fs-900);
    /* padding-top: var(--mt-200); */
    padding-bottom: var(--mt-200);
    text-align: center;
    font-family: "Product-Sans-Medium";
}

.electricFleet a {
    text-decoration: none;
    font-size: var(--fs-200);
    font-family: "Product-Sans-Regular";
    color: #00aa02;
}