.mainContainer {
    display: flex;
    justify-content: center;
}

.container {
    position: absolute;
    top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -1;
    width: 100%;
}

.subContainer {
    width: 83%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 2px solid green; */
}

.newsRoomHeadings {
    display: flex;
    margin-top: var(--pl-100);
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    width: 100%;
    /* border: 2px solid red; */
}

.newsRoomHeadings h5 {
    font-size: var(--fs-700);
    font-family: "Product-Sans-Medium";
    justify-content: center;
    text-align: center;

}

.newsRoomHeadings h2 {
    color: #063767;
    font-family: "Product-Sans-Medium";
    font-size: var(--fs-100);
    padding-top: var(--mt-200);
    padding-bottom: var(--mt-200);
    justify-content: center;
    text-align: center;
}

.newsLetterContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    /* border: 2px solid red; */
    margin-top: var(--pl-100);
}

.newsLetterCard img {
    width: 100px;
    object-fit: contain;
}

@media screen and (min-width:992px) {
    .newsLetterContainer {
        width: 80%;

    }

    .newsLetterCard img {
        width: 140px;
        object-fit: contain;
    }

}

.newsLetterCard {
    background-color: #f8f9fa;
    padding: var(--mt-200);
    padding-top: var(--fs-10);
    padding-bottom: var(--fs-10);
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 5%;
    width: 100%;
}



.newsLetterContent {
    display: flex;
    flex-direction: column;

}

.newsLetterContent h5 {
    font-size: var(--fs-700);
    font-family: "Product-Sans-Medium";
}

.newsLetterContent a {
    font-family: "Product-Sans-Regular";
    font-size: var(--fs-200);
    color: #0096d5;
    text-decoration: none;
}

.awardsHeading {
    margin-top: var(--pl-100);

}

.awardsHeading h2 {
    color: #063767;
    font-family: "Product-Sans-Medium";
    font-size: var(--fs-100);
    /* padding-top: var(--mt-200);
    padding-bottom: var(--mt-200); */
    justify-content: center;
    text-align: center;
}

.awardsContainer {
    display: flex;
    flex-direction: column;
    margin-top: var(--pl-100);
    margin-bottom: var(--pl-100);
    background-color: #eff1f5;
    padding: var(--fs-10);
    /* align-items: center;
    justify-content: center;
    text-align: center; */
    padding-left: 5%;
    padding-right: 5%;
    list-style-type: none;
}
.awardsCard {
    display: flex;
    /* align-items: center; */
    /* border: 2px solid red; */
    gap: 5%;
    height: 100%;
    margin-bottom: var(--pl-100);
}
.awardsCard img{
    width: 70px;
    height: 70px;
    object-fit: contain;
    /* border: 2px solid blue; */
}

@media screen and (min-width:769px) {
    .awardsContainer{
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        
    }
    .awardsCard{
        width: 45%;
    }
    .awardsCard img{
        width: 80px;
        height: 80px;
    }
    
}
@media screen and (min-width:992px) {
    .awardsCard img{
        width: 90px;
        height: 90px;
    }
    
}




/* .awardContent{
    border: 2px solid green;
} */
.awardContent h5 {
    font-size: var(--fs-900);
    /* font-weight: 200; */
    font-family: "Product-Sans-Medium";
    text-align: start;
}

.awardContent p {
    font-size: var(--fs-200);
    /* font-weight: 200; */
    font-family: "Product-Sans-Regular";
    text-align: start;
    /* padding-top: var(--pt-100); */
}