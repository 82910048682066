.cd-headline.rotate-1 .cd-words-wrapper {
    display: inline-block;
    perspective: 300px;
}

.cd-headline.rotate-1 b {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: rotateX(180deg);
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
}

.cd-headline.rotate-1 b.is-visible {
    position: relative;
    opacity: 1;
    transform: rotateX(0deg);
}

.cd-headline.rotate-1 b {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: rotateX(180deg);
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    /* font-weight: 500; */
    font-size: var(--fs-900);
    font-family: "Product-Sans-Regular";
}

.cd-headline.rotate-1 b.is-visible {
    position: relative;
    opacity: 1;
    transform: rotateX(0deg);
    animation: cd-rotate-1-in 1.2s;
}

.cd-headline.rotate-1 b.is-hidden {
    transform: rotateX(180deg);
    animation: cd-rotate-1-out 1.2s;
}

@keyframes cd-rotate-1-in {
    0% {
        transform: rotateX(180deg);
        opacity: 0;
    }

    35% {
        transform: rotateX(120deg);
        opacity: 0;
    }

    65% {
        opacity: 0;
    }

    100% {
        transform: rotateX(360deg);
        opacity: 1;
    }
}

@keyframes cd-rotate-1-out {
    0% {
        transform: rotateX(0deg);
        opacity: 1;
    }

    35% {
        transform: rotateX(-40deg);
        opacity: 1;
    }

    65% {
        opacity: 0;
    }

    100% {
        transform: rotateX(180deg);
        opacity: 0;
    }
}