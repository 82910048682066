.footerMainContainer {
    width: 100 !important;
}

.forbesImg {
    width: 100%;
    object-fit: contain;
}


.footerContainer {
    /* position: absolute; */
    background-color: #f8f9fa;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    /* padding: 20px; */
    /* align-items: center; */
    margin-top: 20px;
    /* bottom: 0px; */
    /* top: 100%;
    margin-top: 100%; */
}

.subFooterContainer {
    width: 83%;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

@media (min-width:769px) {

    .subFooterContainer {
        flex-direction: row;
        /* align-items: center; */
        /* justify-content: flex-start;
        align-items: flex-start; */
    }
}

.logoContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    /* border: 2px solid red; */
    /* width: 50%; */
    /* width: 83%; */
    justify-content: center;
}

@media (min-width:769px) {
    .logoContainer {
        width: 50% !important;
        /* border: 1px solid red; */
        justify-content: flex-start;
        /* justify-content: space-between; */
    }
}

.logoContainer h1 {
    font-size: var(--fs-100);
    color: #a1a1a1;
    font-family: "Product-Sans-Medium";
}

.logoContainer h5 {
    font-size: var(--fs-300);
    /* padding-bottom: var(--mt-200); */
    font-family: "Product-Sans-Medium";
}

.logo {
    width: 140px;
}

@media (max-width: 992px) {
    .logo {
        min-width: 140px;

    }

}

.rightFooter {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media (min-width:769px) {
    .rightFooter {
        flex-wrap: nowrap;
        justify-content: flex-start;
        width: 100%;
        /* border: 2px solid green; */
    }
}

.serviceContainer {
    width: 50%;
    /* margin-top: 20px; */
}

.serviceContainer h2 {
    padding-top: var(--pt-500);
    font-size: var(--fs-200);
    font-family: "Product-Sans-Medium";
}

.serviceContainer a {
    text-decoration: none;
}

.serviceContainer h6 {
    font-size: var(--fs-400);
    font-family: "Product-Sans-Medium";
    padding-top: var(--pt-300);
    padding-bottom: var(--pt-300);
    color: #000000;
}

.serviceContainer h3 {
    font-size: var(--fs-300);
    font-family: "Product-Sans-Medium";
    padding: 0px;
    margin: 0px;
}

.serviceContainer p {

    font-size: var(--fs-400);
    font-family: "Product-Sans-Medium";
    color: #a1a1a1;
}

.phone {
    text-decoration: none;
    color: #a1a1a1;
}

.inputContainer {
    display: flex;
    width: 100%;

}

.input {
    border: 0px;
    /* width: 100%; */
    font-size: var(--fs-300);
    font-family: "Product-Sans-Medium";
    padding: 8px;
    outline: none;
}

.input::placeholder {
    font-family: "Product-Sans-Medium";
}

.arrowIcon {
    background-color: #0096d5;
    border: 0px;
    /* padding: var(--pt-600); */

    padding: 8px;
}

.arrowIcon i {
    color: #fff;
}

.MediaContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    /* border: 2px solid blue; */
}

/* .MediaContainer h3 {
    font-size: var(--fs-300);
    font-family: "Product-Sans-Medium";
    padding: 0px;
    margin: 0px;
    padding-top: var(--pt-300);
} */

.mediaImgCon {
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
}

.mediaImgCon a img {
    width: 15px;
    height: 15px;
    object-fit: contain;
}

.appContainer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.appImgCon {
    display: flex;
    margin-top: 10px;
}

.appImgCon a img {
    width: 80%;
    display: flex;
    gap: 10px;
}

.line2 {
    /* margin-top: var(--mt-300); */
    /* margin-bottom: var(--ht-400);
    margin-left: 45%; */
    background-color: black;
    height: var(--ht-10);
    width: var(--wd-100);
}

.copyrightContainer {
    justify-content: center;
    gap: var(--pt-500);
    display: flex;
    font-size: var(--fs-500);
    color: #a1a1a1;
}

.copyrightContainer a {
    font-size: var(--fs-500);
    color: #a1a1a1;
    text-decoration: none;
    font-family: "Product-Sans-Regular";

}


.errorMsg {
    color: red;
    font-family: "Product-Sans-Regular  ";
}